//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FsLightbox from 'fslightbox-vue';
import i18n from '@/vueI18n';
import { mapActions, mapGetters } from 'vuex';
import { scroll } from 'quasar';
const { getScrollTarget, setScrollPosition } = scroll;
export default {
  components: {
    FsLightbox,
  },

  methods: {
    openImage(image) {
      this.toggler = !this.toggler;
      this.imageUrl = image;
    },
    scrollTop() {
      var offset = 0;
      const ele = document.getElementById('header');
      var target = getScrollTarget(ele);
      var duration = 0;
      this.drawer = false;
      setScrollPosition(target, offset, duration);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      doFetchImages: 'gallery/doFetchImages',
    }),
    onLoad(index, done) {
      setTimeout(async () => {
        if (this.index <= this.galleries) {
          await this.doFetchImages();
          done();
        }
      }, 2000);
    },
  },
  computed: {
    ...mapGetters({
      images: 'gallery/images',
      // loading: 'gallery/loading',
      index: 'gallery/index',
    }),
    language() {
      return i18n.locale;
    },
  },
  async mounted() {
    this.scrollTop();
    await this.doFetchImages();
  },
  watch: {
    language() {
      document.title =
        this.i18n('app.title') +
        ' | ' +
        this.i18n('app.gallery');
    },
  },
  data() {
    return {
      loaded: false,
      toggler: false,
      imageUrl: '',
      galleries: 36,
    };
  },
};
